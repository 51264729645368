/**
 * Components mobile global config
 **/
(function(app, $) {
	/*************** publish app.components.config.global  ***************/
	app.componentsconfig = app.componentsconfig || {};
	app.componentsconfig.mobile = app.componentsconfig.mobile || {};
	app.componentsconfig.mobile.global = {
		global : {
			components : {
				'global.categoriesNavigation' : {},
				'global.accordion' : {},
				'global.simplesearch' : {
					enabled : false
				},
				'global.scroll' : {
					enabled : false
				},
				'newsletter.custom' : {}
			}
		},
		storefront : {
			components : {
				'storefront.mobile' : {}
			}
		},
		search : {
			components : {
				'producttile.slider' : {},
				'search.mobile' : {}
			}
		},
		product : {
			components : {
				'global.zoom360degree' : {
					primaryImage : function(){
							var activeItem = $('.js-mobile_thumbnails_slider .b-owl_carousel-item_active img.b-owl_carousel-image');
							if (activeItem.length > 0) {
								return activeItem;
							} else {
								return $('.js-mobile_thumbnails_slider img.b-owl_carousel-image');
							}
						},
					autoStart    : false
				},
				'product.slider' : {},
				'product.mobile' : {},
				'video.mobile': {},
				'global.scroll' : {
					enabled : true
				}
			}
		},
		wishlist : {
			components : {
				'product.mobile' : {}
			}
		},
		checkout : {
			components : {
				'product.mobile' : {}
			}
		}
	};
}(window.app = window.app || {}, jQuery));
