/**
 * The component shows correspond banner for each 2-nd level category. 
 * If category does not have a banner then top category banner showed. 
 * 
 * Menu structure
 * 
 * - TopCategory1       (banner1default)
 *   - Level2category11 (banner11)
 *   - Level2category12 (banner12)
 * - TopCategory2       (banner2default)
 *   - Level2category21 (banner21)
 *   - Level2category22 (banner22)
 *   
 */
(function (app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			timerId: null,
			navigation: $('.js-main_navigation'),
			navigationWrap: $('.js-menu_subcategory_wrapper'),
			window: $(window),
			document: $(document),
			mobileDevice: 'mobile',
			menuCategorySel: '.js-main_navigation',
			menuCategoryMobileActive: 'm-active',
			menuCategoryUl: '.js-menu_category-ul',
			disableLinks: '.l-main_navigation.m-active .b-menu_category-link',
			mActiveLevel2Links: '.l-main_navigation.m-active .b-menu_category-level_2-link',
			withSubcategories: 'm-with_subcategories',
			hHidden: 'h-hidden',
			tabletHeaderMenuNav: 'tablet-header-menu-nav',
			level3CategoryMenu: 'b-menu_category-level_3',
			jsCtgChosenSelector: '.js-ctg-chosen',
			jsCtgChosenClass: 'js-ctg-chosen',
			jsCtgEmptyFlyoutSelector: '.js-cloned-config-subitem',
			jsMenuSubcatWrapper: '.js-menu_subcategory_wrapper',
			jsMenuLevel2ItemSel: '.js-menu_category-level_2-item',
			mWithoutBannerClass: 'm-without_banner',
			jsMenuCtg2BannerDef: '.js-menu_category-level_2-banner-default',
			jsMenuCtg2Banner: '.js-menu_category-level_2-banner',
			level2MenuContCurrent: 'b-menu_category-container--current',
			level2MenuContHidden: 'b-menu_category-container--current-hidden',
			level3ItemCurrent: 'b-menu_category-level_3-item--current',
			level3MenuBlock: '.js-menu_category-level_3',
			filterCategoryItemSel: '.js-refinement',
			menuCategoryLevel3Selector: '.js-menu_category-level_3-link',
			classes: {
				menuCurrentCategoryLevel1: 'm-current b-menu_category-item--current',
				menuCurrentCategoryLevel2: 'b-menu_category-level_2-link--current',
				menuCurrentCategoryLevel3: 'b-menu_category-level_3-item--current',
				menuCurrentCategoryLevel4: 'b-menu_category-level_3-item--current',
				mCurrentCategory: 'm-current_category',
				firstLevelPreselected: 'js-first-level-preselected'
			}
		};
	}

	function initializeDOM(){
		var headerUl = $($cache.menuCategoryUl);
		if(headerUl.length){
			app.util.determineListLineBreak(headerUl.get(0));
		}
	}

	//auxiliary function for displaying subcategories with delay
	function showItem(){
		var $thisLevel = $(document).find($cache.jsCtgChosenSelector);
		var $wrapper = $thisLevel.find($cache.jsMenuSubcatWrapper);
		if ($cache.timerId){
			return false;
		}
		$wrapper.show();
	}

	// Marks current category and it's parents
	function markCurrentCategory() {
		var currentLevelCategory;
		var currentCategoriesArray = app.trackerData.categoryIDPathGPF || [];

		if (app.currentDevice !== $cache.mobileDevice) {
			for (var i = 0; i < currentCategoriesArray.length; i++) {
				currentLevelCategory = $cache.navigation.find(`[data-category=${currentCategoriesArray[i]}]`);

				if (currentLevelCategory.length) {
					currentLevelCategory.first().addClass($cache.classes['menuCurrentCategoryLevel' + (i + 1)]);
				}
			}
		} else {
			currentLevelCategory = currentCategoriesArray.length ? $cache.navigation.find(`[data-category=${currentCategoriesArray[currentCategoriesArray.length - 1]}]`) : {};

			if (currentLevelCategory.length) {
				currentLevelCategory.first().addClass($cache.classes.mCurrentCategory);
				var mainCategory = $cache.navigation.find(`[data-category=${currentCategoriesArray[0]}]`);

				if (mainCategory.length && mainCategory.first().data('open-by-default')) {
					mainCategory.first().addClass($cache.classes.firstLevelPreselected);
				}
			}
		}
	}

	function ipadTriggerMouseleave(hoveredItem) {
		var chosenItem = $($cache.jsCtgChosenSelector);

		if (navigator.userAgent.match(/iPad/i) && chosenItem.length > 0 && !hoveredItem.hasClass($cache.jsCtgChosenClass)) {
			chosenItem.trigger('mouseleave');
		}
	}

	function initializeEvents() {
		// prevents plp open on tablet | opens level3 category list, when click on level2 category 
		$cache.document.on('click', $cache.mActiveLevel2Links, function(e){
			var $this = $(this),
				currentItem = $this.parents($cache.jsMenuLevel2ItemSel),
				subMenu = currentItem.find($cache.jsMenuSubcatWrapper),
				isActive = $this.data('active'),
				$menu = $('.b-menu_category-level_2-wrapper .js-menu_category-level_3');

			if(!isActive || $($cache.menuCategorySel).hasClass($cache.menuCategoryMobileActive) && $.trim(subMenu.find('ul').html())) {
				e.preventDefault();

				if (!subMenu.hasClass($cache.hHidden)) {
					subMenu.toggleClass($cache.hHidden);
					return;
				}

				$menu.addClass($cache.hHidden);
				subMenu.toggleClass($cache.hHidden);
			}
		});

		$cache.window.resize(function(){
			var headerUl = $($cache.menuCategoryUl);
			if(headerUl.length){
				app.util.determineListLineBreak(headerUl.get(0));
			}
		});

		// top level on-mouseenter
		$('.js-menu_category-item').on('mouseenter', function(e) {
			e.preventDefault();
			var $thisLevel = $(this);
			var $defaultBanner = $thisLevel.find($cache.jsMenuCtg2BannerDef);
			var $allBanners = $thisLevel.find($cache.jsMenuCtg2Banner);
			var $wrapper = $thisLevel.find($cache.jsMenuSubcatWrapper);
			ipadTriggerMouseleave($thisLevel);

			if (!$thisLevel.hasClass('b-menu_category-item--current')) {
				$('.js-menu_category-item.b-menu_category-item--current').addClass('b-menu_category-item--current-hidden');
			}

			if (!$thisLevel.find($cache.jsCtgEmptyFlyoutSelector).length || (app.device.currentDevice() !== 'desktop')) {
				if ($cache.timerId) {
					$thisLevel.toggleClass($cache.jsCtgChosenClass);
					setTimeout(function() {
						$(document).trigger('categoryflyoutopen');
					}, 600);
					return false;
				} else {
					$wrapper.show();
					$thisLevel.toggleClass($cache.jsCtgChosenClass);
					$cache.navigationWrap.removeClass($cache.mWithoutBannerClass);
					$allBanners.hide();
					$defaultBanner.show();
					$(document).trigger('categoryflyoutopen');

					// if there is no default banner for category
					if (!$defaultBanner.html()) {
						$cache.navigationWrap.addClass($cache.mWithoutBannerClass);
					}

					// bind mouseenter event handler for 2nd level onetime only
					if (!$thisLevel.data('isInitedlevel2')) {
						var $this = $(this);

						$thisLevel.data('isInitedlevel2', true);
						$thisLevel.find('.js-menu_category-level_2-link').on('mouseenter', function() {
							var $banner = $defaultBanner;
							var index = $this.attr('data-slot');

							if (index) {
								var $curBanner = $thisLevel.find('.js-menu_category-level_2-banner-' + index);
								if ($curBanner.html()) {
									$banner = $curBanner;
									$cache.navigationWrap.removeClass($cache.mWithoutBannerClass);
								} else {
									$cache.navigationWrap.addClass($cache.mWithoutBannerClass);
								}
							}

							$allBanners.hide();
							$banner.show().addClass('m-active').siblings('div').removeClass('m-active');
						});
					}
				}
			}
		});

		//hide subcategories block with a short delay
		$('.js-menu_category-item').on('mouseleave', function(){
			var $thisLevel = $(this);
            $('.js-menu_category-item').not('.js-ctg-chosen').removeClass('b-menu_category-item--current-hidden');
			if ($thisLevel.hasClass($cache.jsCtgChosenClass)){
				var $wrapper = $thisLevel.find($cache.jsMenuSubcatWrapper);
				$thisLevel.toggleClass($cache.jsCtgChosenClass);
				$cache.timerId = setTimeout(function(){
					$wrapper.hide();
					$cache.timerId=null;
					showItem();
				}, 600);
			}
		});

        //Remove active classes for level3 and level2 menu on hover
		$($cache.jsMenuLevel2ItemSel).on('mouseenter', function() {
			if(!$(this).hasClass($cache.level2MenuContCurrent)) {
				$('.' + $cache.level2MenuContCurrent).addClass($cache.level2MenuContHidden);
			}
		}).on('mouseleave', function() {
            if(!$(this).hasClass($cache.level2MenuContCurrent)) {
                $('.' + $cache.level2MenuContCurrent).removeClass($cache.level2MenuContHidden);
            }
        });

		// general cases for level3 menu
		$($cache.menuCategoryLevel3Selector).on('mouseenter', function(e) {
			e.preventDefault();
			var $this = $(this);
			if(!$this.hasClass($cache.level3ItemCurrent)) {
                $this.parents($cache.level3MenuBlock).find('.'+ $cache.level3ItemCurrent).addClass($cache.level3ItemCurrent + '-hidden')
			}
		});

		$($cache.menuCategoryLevel3Selector).on('mouseleave', function(e) {
			e.preventDefault();
            $('.' + $cache.level3ItemCurrent).removeClass($cache.level3ItemCurrent + '-hidden')
		});

		$cache.document.on('click', $cache.disableLinks, function(e){
			if ($(this).parents('div.' + $cache.tabletHeaderMenuNav).length === 0) {
				e.preventDefault();
			}
		});

		// add hidden class if filter category column is empty
		$($cache.filterCategoryItemSel).each(function() {
			var $this = $(this);
			if ($this.children().length === 0) {
				$this.addClass('h-hidden');
			} else {
				$this.removeClass('h-hidden');
			}
		});

		markCurrentCategory();
	}
	/**
	 * @namespace app.components.global.categoryflyout public object
	 **/

  app.components = app.components || {};
  app.components.categoryflyout = app.components.categoryflyout || {};
  app.components.categoryflyout.custom = {
		// initializations
		init : function () {
			initializeCache();
			initializeDOM();
			initializeEvents();
		}
	};

})(window.app = window.app || {}, jQuery);