(function (app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			document: $(document),
			window : $(window),
			body : $('body'),
			header: $("header"),
			mainHeader : $('.js-header_main'),
			headerIcons: $('.js-search-icon, .js-mini_cart-title'),
			minicartContainer: $(".js-mini_cart-flyout"),
			minicartContainerSel: ".js-mini_cart-flyout",
			minicartTitle: $(".js-mini_cart-title"),
			minicartQty: $(".b-minicart-quantity_value"),
			miniCartEmpty : "b-mini_cart-empty",
			miniCartEmptySel : ".b-mini_cart-empty",
			leftHeaderNavigation : $('.l-main_navigation'),
			menuButton: $(".js-vertical_menu-button"),
			tabletHeaderMenuNav: $('.tablet-header-menu-nav'),
			promoBanner: $('.js-header-promo-container'),
			promoBannerItemSelector: '.js-header-promo-item',
			promoBannerHeight: 0,
			closePromoButtonSelector: '.js-header-promo-close',
			slideEffect : "easeInExpo",
			headerChangeEvent : 'header.change',
			hMinimizedClass_hHiddenClass : "h-minimized h-hidden",
			owlCarouselSel : ".js-owl_carousel",
			promoBannerCookieName : app.preferences.promoBannerCookieName,
			promoHideTimeout : 1 * 60 * 60 * 1000, // Hours * minutes * seconds * milliseconds
			subCategory : ".js-menu_category-level_2-list",
			headerServiceMenuItem : $(".js-header_service_menu-item"),
			headerServiceMenuItemToggler: '.js-toggler',
			shippingInfoIconSel: '.js-shipping-info-icon-custom',
			shippingInfoCloseIconClass: 'js-shipping-info-close_icon',
			shippingInfoContentSel: '.js-shipping-info-content',
			hiddenClass: 'h-hidden'
		};
		$cache.event = {
			headerChange 	: 'header.change',
			minicartRemove 	: 'minicart.product.removed',
			minicartShow 	: 'minicart.show'
		};
	}

	function initializeHelpers() {
		if (!app.device.isMobileView()){
			app.components.global.minicart.show = function (){
				return;
			};
		};
	}

	function initializeDom() {
		if (app.device.isTabletUserAgent()) {
			$($cache.subCategory).parent().slimScroll({height: '100%'});
		}
	}

	function adaptBodyPadding(){
		var headerHeight = $cache.mainHeader.height();
		$cache.body.css('padding-top', headerHeight + 'px');
	}

	function initPromoBanner() {
		var promoItems = $cache.promoBanner.find($cache.promoBannerItemSelector).clone();
		var promoItemsLength = promoItems.length;
		var promoCloseContainer = $cache.promoBanner.find($cache.closePromoButtonSelector).parent().clone();

		if (promoItemsLength) {
			var currentIndex = 0;

			$cache.promoBanner.html(promoItems.eq(currentIndex));
			$cache.promoBanner.append(promoCloseContainer);

			setInterval(function() {
				if (currentIndex === promoItemsLength - 1) {
					currentIndex = 0;
				} else {
					currentIndex++;
				}

				$cache.promoBanner.html(promoItems.eq(currentIndex));
				$cache.promoBanner.append(promoCloseContainer);
			}, 5000);
		}

		if (!$.cookie($cache.promoBannerCookieName)) {
			$cache.promoBanner.removeClass($cache.hMinimizedClass_hHiddenClass);
		} else {
			$cache.promoBanner.addClass($cache.hMinimizedClass_hHiddenClass);
		}

		$cache.promoBannerHeight = parseInt($cache.promoBanner.outerHeight());

		$cache.document.on('click', $cache.closePromoButtonSelector, function() {
			var expiration = new Date();

			$cache.promoBanner.addClass($cache.hMinimizedClass_hHiddenClass);

			expiration.setTime($cache.promoHideTimeout + expiration.getTime());
			$.cookie($cache.promoBannerCookieName, true, { expires: expiration, path: '/' });

			$cache.header.trigger($cache.event.headerChange, {height: $cache.promoBannerHeight});
		});
	}

	function initializeEvents() {
		$cache.headerServiceMenuItem.on('keydown', function(e){
			if (e.keyCode === 13) {
				e.preventDefault();
				$(e.target).find($cache.headerServiceMenuItemToggler).trigger('click');
			}
		});

		$cache.headerIcons.on('click', function (){
			$cache.header.trigger('header.change', {
				headerHeight: $cache.header.height()
			});
		});

		if($cache.mainHeader.length && $cache.mainHeader.css('position') === 'fixed'){
			new ResizeSensor($cache.mainHeader.get(0), function(){
				adaptBodyPadding();
			});

			$cache.window.resize(function(){
				adaptBodyPadding();
			});
		}

		/**
		 * Event "minicart.show" triggers after adding product to cart
		 * Field 'html' in data object should contain rendered minicart
		 */
		$cache.document.on($cache.event.minicartShow + " " + $cache.event.minicartRemove, function(event, data) {
			var content = $(data.html).find($cache.minicartContainerSel);

			$cache.minicartContainer.html(content.html());

			var cartQty = $(".js-cart_qty").text(),
				emptyBasket = $cache.minicartQty.parents($cache.miniCartEmptySel),
				owlCarouselInside = $cache.minicartContainer.find($cache.owlCarouselSel);
			if (cartQty && emptyBasket.length > 0) {
				emptyBasket.removeClass($cache.miniCartEmpty);
			}

			$cache.minicartQty.text(cartQty || '');

			if ( cartQty.length ) {
				if(owlCarouselInside.length){
					if (!('silent' in data) || !data.silent) {
						owlCarouselInside.one('owl.afterUpdate', function() {
							$cache.minicartTitle.trigger('click', { useAutoCloseTimer: true });
						});
					}
				} else {
					$cache.minicartTitle.trigger("click", {"useAutoCloseTimer" : true});
				}
				app.owlcarousel.init();
			} else {
				$cache.document.trigger('toggle.hideall');
				$cache.header.trigger('header.change');
			}

			$cache.document.trigger("minicart.load");
		});

		// show|hide tablet menu with categories & customer services|contact links, hide 3rd level categories
		$cache.menuButton.on('click', function() {
			$cache.document.trigger('toggle.hideall');
			$cache.header.trigger('header.change');

			$cache.menuButton.toggleClass('m-active');
			$cache.tabletHeaderMenuNav.toggleClass('h-hidden');
			$cache.document.find('.b-menu_category-level_3').toggleClass('h-hidden');
			if($cache.leftHeaderNavigation.css('display') == 'none'){
				$cache.leftHeaderNavigation.toggleClass('m-active');
			}
			else if($cache.leftHeaderNavigation.css('display') == 'block'){
				$cache.leftHeaderNavigation.toggleClass('m-active');
				$cache.document.find('.b-menu_category-level_3').removeClass('h-hidden');
			}
		});

		//navigation menu is closing, if click outside on tablet
		$cache.document.on('click', hideNavigation);
		$('.js-toggler').on('click', hideNavigation); // to bypass toggler's e.stopPropagation()

		function hideNavigation(e) {
			if ( ($cache.menuButton.hasClass('m-active')) && !($(e.target).hasClass('l-main_navigation')) && !($(e.target).parents('.l-main_navigation.js-main_navigation.m-active').length || $(e.target).hasClass('js-vertical_menu-button')) ){
				e.preventDefault();
				$cache.menuButton.toggleClass('m-active');
				$cache.tabletHeaderMenuNav.toggleClass('h-hidden');
				$cache.leftHeaderNavigation.toggleClass('m-active');
				$cache.document.find('.b-menu_category-level_3').removeClass('h-hidden');
			}
		}

		initShippingInfoPopup();
	}

	function initShippingInfoPopup() {
		$cache.document.on('click', $cache.shippingInfoIconSel, function() {
			var shippingInfoCont;

			if (app.device.isMobileView()) {
				var shippingInfo = $(this);
				shippingInfoCont = $($cache.shippingInfoContentSel);

				if (shippingInfoCont.find('.content-asset').children().length > 0) {
					app.fancybox.open(shippingInfo, {
						content: shippingInfoCont.html(),
						wrapCSS: 'shipping-info-popup',
						autoSize: false,
						afterClose: function() {
							$cache.document.trigger('product.added');
						}
					});
				}
			} else {
				shippingInfoCont = $($cache.shippingInfoContentSel);

				if (shippingInfoCont.hasClass($cache.hiddenClass)) {
					shippingInfoCont.removeClass($cache.hiddenClass);

					$cache.document.on('click.shippingInfo', function(closingEvent) {
						if ($(closingEvent.target).hasClass($cache.shippingInfoCloseIconClass) || !shippingInfoCont.has(closingEvent.target).length) {
							shippingInfoCont.addClass($cache.hiddenClass);
							$cache.document.off('click.shippingInfo');
						}
					});
				} else {
					$cache.document.off('click.shippingInfo');
				}
			}
		});
	}

	/*************** app.components.global.header public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.header = {
		// initializations
		init : function (params) {
			initializeCache();
			if(!params.disablePromoInConfigurator){
				initPromoBanner();
			}
			initializeDom();
			initializeHelpers();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
