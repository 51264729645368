(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			wishlistElement: {},
			header: $('header'),
			document: $(document),
			loginToggler: $('.js-login_dropdown-title.js-toggler'),
			wishlistToggler: $('.b-wishlist_flyout-title.js-toggler'),
			wishlistFlyoutSel: '.js-wishlist_dropdown-flyout',
			wishlistQtyVal: $('.js-wishlist-quantity_value'),
			loginTogglerContent: $('.js-login_dropdown'),
			multiTemplate: $('#multiwishlist').html(),
			wishlistFlyoutListSel: '.js-wishlist_flyout-list',
			wishlistQtySel: '.js-wishlist_qty',
			wishlistRemoveSel: '.js-wishlist_flyout-remove',
			carouselOwlSel: '.js-owl_carousel',
			wishlistEmptyClass: 'b-wishlist_empty',
			wishlistAddedClass: 'b-add_to_wishlist--added',
			headerAddtocartSel: '.js-wishlist_dropdown-flyout .js-add_to_cart_button'
		};
	}
	function initializeHelpers() {
		app.components.account.fakelogin.show = function() {
			$cache.document.trigger('wishlist.notauthenticated');
		};
	}
	function initializeEvents() {
		$cache.document.on('wishlist.added wishlistblock.removed', function(e, wishlistQuantity, doNotToggleBlock) {
			$cache.wishlistFlyout = $($cache.wishlistFlyoutSel);

			if (typeof doNotToggleBlock === 'undefined') {
				doNotToggleBlock = wishlistQuantity;
			}

			if (app.page.ns !== 'checkout') {
				var wishlistCount = $($cache.wishlistQtySel).eq(0).text() || '0';

				$cache.wishlistQtyVal.toggleClass('h-hidden', !parseInt(wishlistCount));
				$cache.wishlistQtyVal.text(wishlistCount);
				app.wishlist.updateTooltipVal(wishlistCount);

				if (!parseInt(wishlistCount)) {
					$cache.wishlistToggler.addClass($cache.wishlistEmptyClass);
				}

				if (doNotToggleBlock !== true) {
					var triggeredClick = false;

					$($cache.wishlistFlyoutListSel).on('initialized.owl.carousel', function() {
						if (!triggeredClick) {
							$cache.wishlistToggler.trigger('click', 'wishlist');
							triggeredClick = true;
						}
					});
				}

				$cache.wishlistToggler.removeClass($cache.wishlistEmptyClass);
				$cache.wishlistFlyout.find($cache.carouselOwlSel).each(function() {
					app.owlcarousel.initCarousel(this);
				});
			}
		});

		$cache.document.on('wishlist.beforeadded', function(event, element) {
			$cache.wishlistElement = element;
		});

		$cache.document.on('wishlist.notauthenticated', function() {
			$cache.loginToggler.trigger('click', 'wishlist');
		});

		$cache.header.on('click', $cache.headerAddtocartSel, function() {
			var postdata = { Quantity: '1', cartAction: 'add' };
			var $this = $(this);

			postdata.pid = $this.data('pid');
			var customproducts = JSON.stringify($this.data('customproducts'));

			if (typeof customproducts !== 'undefined' && customproducts !== 'null') {
				postdata.customProducts = customproducts;
			}

			app.cart.update(postdata, function(response) {
				$cache.document.trigger('minicart.show', { html: response });
			});
		});

		$cache.header.on('click', $cache.wishlistRemoveSel, function() {
			var $this = $(this);
			var data = {
				pliid: $this.data('item'),
				format: 'ajax'
			};
			var url = app.urls.removeProdyctFromWishlist;

			if (app.preferences.emarsysWishlistReminderEnabled) {
				url = app.util.appendParamToURL(url, 'triggerWishlistReminder', true);
			}

			$.ajax({
				url: url,
				type: 'GET',
				xhrFields: app.corsOrigin ? { withCredentials: true } : undefined,
				data: data
			})
				.done(function(resdata) {
					var $wishlistContainer = $($cache.wishlistFlyoutSel);

					$wishlistContainer.html($(resdata).find($cache.wishlistFlyoutSel).html());
					var wishlistCount = $($cache.wishlistQtySel).eq(0).text() || '0';

					$cache.wishlistQtyVal.toggleClass('h-hidden', !parseInt(wishlistCount));
					$cache.wishlistQtyVal.text($($cache.wishlistQtySel).eq(0).text());
					app.wishlist.updateTooltipVal(wishlistCount);

					if ($($cache.wishlistRemoveSel).length > 0) {
						$wishlistContainer.find($cache.wishlistFlyoutListSel).one('owl.afterUpdate', function() {
							$cache.wishlistToggler.trigger('update.header');
						});
						$wishlistContainer.find($cache.carouselOwlSel).each(function() {
							app.owlcarousel.initCarousel(this);
						});
						$(document).trigger('wishlist.removed', [$wishlistContainer]);
					} else {
						$cache.wishlistToggler.addClass($cache.wishlistEmptyClass);
						$($cache.wishlistElement).removeClass($cache.wishlistAddedClass);
						$cache.document.trigger('toggle.hideall');
						$cache.header.trigger('header.change');
					}
				});

			return false;
		});
	}

	app.components = app.components || {};
	app.components.wishlist = app.components.wishlist || {};
	app.components.wishlist.custom = {
		// initializations
		init: function() {
			initializeHelpers();
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
