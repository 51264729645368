/**
 * Components specific config
 * */
(function(app) {
	var componentsConfig = {
		global: {
			components: {
				'global.toggler': { enabled: false },
				'footer.custom': {},
				'toggler.custom': {},
				'simplesearch.gender': {},
				'global.simplesearch': {
					simpleSearchHandler: app.simpleSearchFunctions && app.simpleSearchFunctions.simpleSearchHandler,
					successSimpleSearchCallback: app.simpleSearchFunctions && app.simpleSearchFunctions.successSimpleSearchCallback
				},
				'global.scrollToTop': {},
				'categoryflyout.custom': {},
				'quickview.custom': {},
				'wishlist.custom': {},
				'account.addresses': { width: '50%', height: 'auto', autoSize: false },
				'account.paymentinstruments': { width: '50%', height: 'auto', autoSize: false },
				'global.sendToFriend': { width: '50%', height: 'auto', autoSize: false },
				'global.sendcartbyemail': { width: '50%', height: 'auto', autoSize: false },
				'newsletter.custom': {},
				'fancybox.modal': {}
			}
		},
		search: {
			components: {
				'search.custom': {},
				'search.filters': { loaderClass: 'refinement-loader' },
				'search.gridcolumnswitcher': {
					list: ['four', 'two'],
					default: {
						desktop: 'four',
						tablet: {
							landscape: 'four',
							portrait: 'two'
						}
					}
				}
			}
		},
		product: {
			components: {
				'product.custom': {},
				'product.fixedimages': {},
				'social.custom': {},
				'global.scroll': {},
				'product.video': {
					mobileEnabled: true
				}
			}
		},
		checkout: {
			components: {
				'checkout.custom': {},
				'social.custom': {},
				'global.stickykit': {
					recalcOn: 'cart.update.models',
					'.js-cart_table-header': {}
				},
				'simplesearch.gender': { enabled: false }
			}
		},
		error: {
			components: {
				'recommendationblock.custom': {}
			}
		},
		storefront: {
			components: {
				'global.videoMgr': {
					elementSelector: '.js-videomgr'
				}
			}
		}
	};

	/** ************* publish app.components.config.specific************** */
	app.componentsconfig = app.componentsconfig || {};
	app.componentsconfig.specific = componentsConfig;
}(window.app = window.app || {}, jQuery));
