(function(app, $) {
	var $cache = {},
		documentEventsInitialized = false;

	function initializeCache(container) {
		$cache = {
			container: container,
			pdpSlidesLink: container.find('.js-product_slide-link'),
			pdpSlideMenu: $('.js-product_slides-menu'),
			thumbnails: container.find('.js-thumbnails'),
			document: $(document),
			openCarePopup: $('.js-care_details-popup'),
			popupContent: $('.js-care_details-content'),
			fancyboxOpen: $('.js-pdp_fancybox_open'),
			horCarouselSel: '.jcarousel-clip-horizontal',
			needHelpLink: $('.js-pdp_need_help_link'),
			needHelpContainer: $('.js-pdp_need_help_link_container'),
			productDetail: $('.js-product_detail'),
			klarnaWidget: $('klarna-placement'),
			configuratorLimitsConfig: app.resources.CONF.PREF_LIMITS || {},
			selectors: {
				addToCart: '.js-add_to_cart',
				slideContent: '.js-product_slide-content',
				soldoutMessageCont: '.js-soldout-message',
				swatchHover: '.b-swatches_size-item',
				lowInStockSelected: '.b-swatches_size-item-selected .b-variation-few_left_message',
				lowInStockMsg: '.js_low-in-stock-msg',
				productDetail: '.js-product_detail',
				configuratorPriceActions: '.js-configurator-price-actions',
				klarnaWidget: 'klarna-placement',
				thumbnailImg: '.js-img_product_thumbnail'
			},
			classes: {
				productAddToCartSubmitAdded: 'b-product_add_to_cart-submit--added',
				pdpSlidesActive: 'b-product_slide-link--active',
				unselectable: 'js-unselectable',
				disabled: 'disabled',
				addToCartActive: 'b-product_add_to_cart-submit--preload'
			}
		};
		$cache.soldoutMsgText = $cache.productDetail.find($cache.selectors.soldoutMessageCont).html();
		$cache.primaryImagesArr = [];
	}
	
	function initializeEvents() {
		$cache.pdpSlidesLink.on("click", function (){
			var $this = $(this),
				activeLinks = $cache.pdpSlideMenu.find("." + $cache.classes.pdpSlidesActive);
			if (!$this.hasClass($cache.classes.pdpSlidesActive)) {
				activeLinks.next().slideUp();
				activeLinks.removeClass($cache.classes.pdpSlidesActive);
			}
			$this.parent().find($cache.selectors.slideContent).slideToggle();
			$this.toggleClass($cache.classes.pdpSlidesActive);
		});
		
		$cache.thumbnails.on("click", function (e){
			e.stopPropagation();
		});
		
		initOpenPopupLinkEvents();
		
		$cache.needHelpLink.on("click", function(e){
			e.preventDefault();
			app.fancybox.open($cache.needHelpContainer.html());
		});
		
		soldoutMessage();

	}
	
	function initializeDocumentEvents() {
		$cache.document
			.on("product.valid", function() {
				$($cache.selectors.addToCart).addClass($cache.classes.addToCartActive);
			})
			.on("product.added", function(e, container) {
				var addToCart = $(container).find($cache.selectors.addToCart);
				
				addToCart.addClass($cache.classes.productAddToCartSubmitAdded).removeClass($cache.classes.addToCartActive);
				addToCart.html(app.resources.ADDED_TO_CART);
			})
			.on('product.lowinstock.load', function() {
				var message = $cache.productDetail.find($cache.selectors.lowInStockSelected).text(),
					soldoutMessageCont = $cache.productDetail.find($cache.selectors.soldoutMessageCont);
				
				if (message) {
					soldoutMessageCont.html(message);
				}
			})
			.on('product.variation.reloaded', (e, data) => {
				$cache.klarnaWidget = data.target.find($cache.selectors.klarnaWidget);
				app.components.global.klarnapayments.siteMessageUpdate();
			})
			.on('carousel.init', function(e, data) {
				if (data.container) {
					app.owlcarousel.initCarousel($(data.container).find($cache.horCarouselSel));
				}
			});
	}
	
	function soldoutMessage(){
		$cache.container.find($cache.selectors.productDetail)
			.on('mouseover', $cache.selectors.swatchHover, function(){
				var $this = $(this),
					message = '',
					soldoutMessageCont = $cache.productDetail.find($cache.selectors.soldoutMessageCont);
				
				if ($this.hasClass($cache.classes.unselectable)) {
					message = $cache.soldoutMsgText;
				}
				else {
					message = $this.find($cache.selectors.lowInStockMsg).text();
				}
				
				if (message) {
					var soldoutMsg = soldoutMessageCont.html() || ' ';
					soldoutMessageCont.html(message).attr('data-soldout-msg', soldoutMsg);
				}
			})
			.on('mouseout', $cache.selectors.swatchHover, function() {
				var soldoutMessageCont = $cache.productDetail.find($cache.selectors.soldoutMessageCont),
					message = soldoutMessageCont.data('soldout-msg');
				if (message) {
					soldoutMessageCont.html(message).removeAttr('data-soldout-msg');
				}
			});
	}
	
	//overridden private function from app.product.js (overlay, width, height changed for fancybox) 
	function initOpenPopupLinkEvents() {
		// onclick event show popup 'care and details'
		$cache.openCarePopup.on("click", function() {
			var fancyParent,
			activeOverlay = false;
			app.fancybox.open( $cache.popupContent, {
				helpers : {
					overlay : true
				},
				autoSize: false,
				width: 410,
				autoHeight: true,
				afterShow: function () {
					fancyParent = $(".fancybox-wrap").parents(); // normally html and body
					fancyParent.on("click", function (e) {
						e.stopPropagation();
						if(activeOverlay) app.fancybox.close();
						activeOverlay = true;
					});
					$(".fancybox-wrap").on("click", function (event) {
						event.stopPropagation();
					});
				},
				afterClose: function () {
					fancyParent.off("click");
				}
			});
		});

		$cache.fancyboxOpen.on("click", function() {
			var fancyboxContent = $(this).data('content');
			if (fancyboxContent) {
				app.fancybox.open( $(fancyboxContent) );
			}
		});
	}
	/*
	 * Make actions before adding to card and validates errors
	 * @param {Object} configuratorObject Object with customization products
	 * @param {errorType} If defined, enables resourceMessage or fancybox popup (message|fancy)
	 * @param {variantData} array of variants for current product
	 * @param {masterProductId} current master product ID
	 */
	function configuratorBeforeAddToCart(configuratorObject, errorType, variantData, masterProductId) {
		errorType = errorType || false;
		var modalOptions = {
				'closeButtonText' : app.resources.get('CONF.OK'), 'closeButtonCssClass' : 'b-modal-btn center'
			},
			body;

		if(!configuratorObject || !configuratorObject.hasOwnProperty("sides") || !configuratorObject.sides.length) {
			return returnError( errorType, 'NOITEMSSELECTED', 'CHOOSEONEPATCH', modalOptions );
		}

		if(app.components.product.configurator.priceObject) {
			if((app.components.product.configurator.priceObject.basePrice === app.components.product.configurator.priceObject.totalPrice) && app.components.product.configurator.isFreePurchasable) {
				return returnError( errorType, 'NOFREEPATCH_TITLE', 'NOFREEPATCH_DESCRIP', modalOptions );
			}
		}

		var itemsCount = Object.keys(variantData.items).length;
		var patchesCount = 0;

		if ( itemsCount > 1 ) {
			var sides = configuratorObject.sides;
			var sidesWithPatches = [];
			for( var sideIndex in sides ) {
				var side = sides[sideIndex];
				if(!side.hasOwnProperty('key')) {
					continue;
				}
				var customProducts = side.customProducts;
				for(var customProduct in customProducts) {
					if ( customProducts[customProduct]['type'] === 'Patch' && variantData.items.hasOwnProperty(side.item) ) {
						patchesCount++;
						if ( sidesWithPatches.indexOf(side.item) === -1  ) {
							sidesWithPatches.push(side.item);
						}
					}
				}
			}
			var sidesWithPatchesCount = sidesWithPatches.length;
			if (patchesCount > 0 ) {
				if ( itemsCount > patchesCount ) {
					return returnError( errorType, 'MIN_PATCHES_TITLE', 'MIN_PATCHES_TEXT', modalOptions );
				}
				else if ( itemsCount > sidesWithPatchesCount ) {
					return returnError( errorType, 'ONE_EMPTY_TITLE', 'ONE_EMPTY_TEXT', modalOptions );
				}
			}
		}

		var outOfLimits = isCustomElementsOutOfLimits(configuratorObject, masterProductId);
		if (outOfLimits.status) {
			delete outOfLimits.status;
			var errorMessage = 'CHOOSEINRANGE';
			errorMessage += (outOfLimits.min && outOfLimits.min > 0) ? '_MIN' : '';
			errorMessage += (outOfLimits.max) ? '_MAX' : '';
			return returnError(errorType, errorMessage, errorMessage, modalOptions, outOfLimits);
		}

		configuratorSaveBntState(true);
		return {
			success: true
		};
	}

	function returnError(errorType, title, text, modalOptions, errorParams) {
		if(errorType === 'fancy'){
			app.components.fancybox.modal.open(
				app.resources.get( "CONF." + title ),
				app.resources.get( "CONF." + text ),
				modalOptions
			);
		}
		configuratorSaveBntState(false);
		return {
			success: false,
			errorMessage: errorType === 'message' && text,
			errorParams: errorParams
		};
	}

	function configuratorSaveBntState(state){
		$($cache.selectors.configuratorPriceActions).toggleClass($cache.classes.disabled, !state);
	}

	function isCustomElementsOutOfLimits(configuratorObject, masterProductId) {
		var limitsConfig = $cache.configuratorLimitsConfig;
		var isOutOfLimits = {
			status: false,
			min: false,
			max: false
		};
		if (!limitsConfig[masterProductId]) {
			return isOutOfLimits;
		}
		var sidesAvailable = {};
		var sidesKeys = Object.keys(configuratorObject.sides);
		for (var sideIndex = 0, sidesLen = sidesKeys.length; sideIndex < sidesLen; sideIndex++) {
			var currentSideData = configuratorObject.sides[sideIndex];
			var sideElements = {};

			for (var cpIndex = 0, cpLen = currentSideData.customProducts.length; cpIndex < cpLen; cpIndex++) {
				var elementType = currentSideData.customProducts[cpIndex].type;
				if (sideElements[elementType] === undefined) {
					sideElements[elementType] = 1;
				} else {
					sideElements[elementType]++;
				}
			}
			sidesAvailable[currentSideData.side] = sideElements;
		}
		var limitsConfigKeys = Object.keys(limitsConfig[masterProductId]);
		for (var i = 0, limitsConfigKeysLen = limitsConfigKeys.length; i < limitsConfigKeysLen; i++) {
			var sideInConfig = limitsConfigKeys[i];
			if (isOutOfLimits.status) {
				break;
			}
			var sideLimits = getLimitsForSide(sideInConfig);
			if (sideLimits) {
				isOutOfLimits.status = true;
				isOutOfLimits.min = sideLimits.min;
				isOutOfLimits.max = sideLimits.max;
				break;
			}
		}
		return isOutOfLimits;

		function getLimitsForSide(sideID) {
			var result = false;
			var sideInAvailabeSides = sidesAvailable[sideID];
			var sideInConfigData = limitsConfig[masterProductId][sideID];

			if (typeof sideInConfigData === 'object') {
				var sideInConfigDataKeys = Object.keys(sideInConfigData);
				for (var j = 0, sideInConfigDataKeysLen = sideInConfigDataKeys.length; j < sideInConfigDataKeysLen; j++) {
					var sideElemInConfig = sideInConfigDataKeys[j];
					var elementsInAvailableSide = sideInAvailabeSides === undefined ? undefined : sideInAvailabeSides[sideElemInConfig];
					var elementsLimitsInConfig = sideInConfigData[sideElemInConfig];

					var elementIsAbsent = (elementsInAvailableSide === undefined && elementsLimitsInConfig.min > 0);
					var elementIsPresent = (elementsInAvailableSide !== undefined && (elementsInAvailableSide < elementsLimitsInConfig.min || elementsInAvailableSide > elementsLimitsInConfig.max));

					if (elementIsAbsent || elementIsPresent) {
						result = elementsLimitsInConfig;
						break;
					}
				}
			}

			return result;
		}
	}

	/**
	* @namespace app.product.custom public object
	*/
	app.components = app.components || {};
	app.components.product = app.components.product || {};
	app.components.product.custom = {
		init : function(params) {
			var container = 'container' in params && params.container instanceof $ ? params.container.find('.js-pdp_primary_content') : $('.js-pdp_primary_content');
			if (!container || container.hasClass('js-product-custom-initialized')) {
				return;
			}
			initializeCache(container);
			initializeEvents();
			container.addClass('js-product-custom-initialized');
			if (!documentEventsInitialized) {
				initializeDocumentEvents();
				documentEventsInitialized = true;
			}
		},

		beforeAddtocart: configuratorBeforeAddToCart
	};

}(window.app = window.app || {}, jQuery));
