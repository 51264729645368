import CSRF from 'oneapp/src/utils/CSRF';

(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			document: $(document),
			body: $('body'),
			hiddenArea: $('.js-newsletter-hidden'),
			formSel: '.js-newsletter-simple-form',
			popupFormSel: '.js-popup_form_for_submit',
			popupFormClass: 'js-popup_form_for_submit',
			wrapperSel: '.js-simple_newsletter-wrapper',
			genderWrapperSel: '.js-gender-wrapper',
			genderFieldsSel: '.js-simple_newsletter_gender',
			languageFieldsSel: '.js-simple_newsletter-language',
			emailFieldSel: '.js-simple_newsletter_email',
			privacyFieldSel: '.js-simple_newsletter_privacy',
			submitBtnSel: '.js-simple_newsletter_submit',
			submitPopupBtnSel: '.js-popup_newsletter_submit',
			backBtnSel: '.js-simple_newsletter-back',
			additionalContentCheck: '.fancybox-wrap',
			additionalContentDetect: '.js-newsletter_additional-content',
			additionalContentDist: 'l-newsletter-popup-fancybox-additional-content',
			privacyPolicyLinkSel: '.js_privacy_policy',
			generalErrorSel: '.js-simple_newsletter_error',
			errorBlockSel: '.f-error_message-block',
			cookieMsgSel: '.js-simple_newsletter-cookie',
			newsletterHidden: '.js-newsletter-hidden',
			simpleEmailLabelSel: '[for="dwfrm_newsletter_simple_email"]',
			simpleNewsletterLinkSel: '.js-simple_newsletter-link',
			simpleNewsletterCloseBtnSel: '.js-close_newsletter',
			bodyFixClass: 'fancybox-body-fix',
			bodyNewsletterClass: 'fancybox-body-newsletterpopup',
			newsletterPopupWrap: '.js-newsletter_popup',
			slideSel: '.js-slide',
			classes: {
				checked: 'checked',
				error: 'f-state-error'
			}
		};
	}

	function initializeEvents() {
		$cache.document
			.on('focus', $cache.emailFieldSel, function(e) {
				var $simpleWrapper = $(this).closest($cache.wrapperSel);
				if (!app.device.isMobileView() && $simpleWrapper.length && !$.fancybox.isOpen) {
					$simpleWrapper.find($cache.newsletterHidden).slideDown();
				}
			})
			.on('click', $cache.submitBtnSel, function(e) {
				e.preventDefault();
				var $this = $(this);
				var form = $this.closest($cache.formSel);
				var $genderWrapper = form.find($cache.genderWrapperSel);
				var genderInputs = $genderWrapper.find($cache.genderFieldsSel);
				$($cache.generalErrorSel)
					.find($cache.errorBlockSel)
					.text('');
				if (!form.valid() || !$genderWrapper.hasClass($cache.classes.checked)) {
					if (!$genderWrapper.hasClass($cache.classes.checked)) {
						$genderWrapper
							.addClass($cache.classes.error)
							.find($cache.errorBlockSel)
							.text($genderWrapper.data('required-text'));

						genderInputs.attr('aria-invalid', true);
					}
				} else {
					genderInputs.attr('aria-invalid', false);
					$this.attr('disabled', 'disabled');
					e.target = $this;
					subscribeUser(form, e);
				}
			})
			.on('click', $cache.submitPopupBtnSel, function(e) {
				e.preventDefault();
				var $this = $(this);
				var form = $this.closest($cache.popupFormSel);

				if (form.valid()) {
					$this.attr('disabled', 'disabled');
					e.target = $this;
					subscribeUser(form, e);
				}
			})
			.on('click', $cache.privacyPolicyLinkSel, function() {
				app.fancybox.open(this.href, {
					type: 'ajax',
					autoSize: false,
					width: 600,
					height: '80%',
					scrolling: 'auto',
					closeBtn: true
				});
				return false;
			})
			.on('fancybox.closed', cleanUp)
			.on('click', $cache.genderWrapperSel, function(e) {
				e.preventDefault();
				setDefaultGenderType.call(e.target, e.currentTarget);
			})
			.on('keypress', $cache.genderWrapperSel, function(e) {
				e.preventDefault();
				setDefaultGenderType.call(e.target, e.currentTarget);
			})
			.on('click', $cache.simpleEmailLabelSel, function(e) {
				e.preventDefault();
				var $label = $(this).closest('label');
				var $newsletterForm = $label.closest($cache.formSel);
				$newsletterForm.find('#' + $label.attr('for')).focus();
			})
			.on('newsletterpopup.opened', function() {
				setPopupCustomClass();
			})
			.on('newsletterpopup.closed', function() {
				$cache.body.removeClass($cache.bodyNewsletterClass);
			})
			.on('change', $cache.languageFieldsSel, function() {
				$(this).closest($cache.formSel).valid();
			});

		if (app.device.isMobileView()) {
			$cache.document
				.on('tap', $cache.wrapperSel, function() {
					if ($(this).find($cache.cookieMsgSel).length) {
						if ($($cache.formSel).length) {
							app.fancybox.close();
						} else {
							var categoryID = (app.page.pageData && app.page.pageData.currentCategoryID) || '';
							var url = app.urls.showNewsletterPage;

							if (categoryID) {
								url = app.util.appendParamToURL(url, 'categoryID', categoryID);
							}
							location.href = url;
						}
					}
				})
				.on('tap', $cache.backBtnSel, function() {
					if (Modernizr.history && history.length > 1) {
						history.back();
						return false;
					}
					return true;
				})
				.on('click', $cache.simpleNewsletterLinkSel, function() {
					$cache.document.trigger('newsletter.popup.close');
				});
		} else if (app.preferences.enableNewsletterPopup) {
			var nlPopupCount = getNewsletterCookie(); // values: null, 1 or 2
			if ((!nlPopupCount || +nlPopupCount < 2) && (app.currentCustomer.getUserClicksNumber() >= app.preferences.minClicksToShowNewsletterPopup)) {
				app.components.global.newsletter.popupHandler();
			}
		}
	}

	function cleanUp() {
		$($cache.genderFieldsSel)
			.prop('checked', false)
			.removeAttr('checked');
		$($cache.formSel + ' select').val('');
		$($cache.genderWrapperSel + ' label').removeClass($cache.classes.checked);
		$($cache.genderWrapperSel)
			.removeClass($cache.classes.checked)
			.removeClass($cache.classes.error)
			.find($cache.errorBlockSel)
			.text('');
		$($cache.generalErrorSel)
			.find($cache.errorBlockSel)
			.text('');
		if (!app.device.isMobileView() && $($cache.wrapperSel).length) {
			$cache.hiddenArea.filter($cache.slideSel).slideUp();
		}
	}
	function subscribeUser(form, evt) {
		var isPopupForm = form.hasClass($cache.popupFormClass);
		var target = $(evt.target);
		var url = isPopupForm ? app.urls.submitNewsletterPopup : app.urls.submitSimpleSubscription;
		var data = form.serializeArray();

		data.push({ name: 'source', value: form.data('source') });

		if (app.newsletterSubscriptionSource) {
			data.push({ name: 'customSource', value: app.newsletterSubscriptionSource });
		}

		CSRF.populatePayloadByCSRFToken(data)
			.then((payload) => $.ajax({
				url: url,
				type: 'POST',
				xhrFields: app.corsOrigin ? { withCredentials: true } : undefined,
				data: payload
			}).done(function(response) {
				target.removeAttr('disabled');

				if (typeof response === 'object') {
					if (response.redirectURL) {
						app.page.redirect(response.redirectURL);
					}

					$($cache.generalErrorSel + ' ' + $cache.errorBlockSel).text(response.message);
				} else {
					var emailField = form.find($cache.emailFieldSel);
					var privacyField = form.find($cache.privacyFieldSel);

					privacyField.prop('checked', false);

					$cache.document.trigger('newsletter.subscribed', emailField.val());
					$.cookie('nlPopupCount', 2, { expires: 365, path: '/' });

					if (app.device.isMobileView()) {
						var formRect = $($cache.popupFormSel)
							.get(0)
							.getBoundingClientRect();
						var absoluteFormTop = formRect.top + window.pageYOffset;
						var middle = absoluteFormTop - window.innerHeight / 2;

						window.scrollTo(0, middle);

						$($cache.popupFormSel)
							.parents($cache.newsletterPopupWrap)
							.html(response);
					} else {
						emailField.blur();
						emailField.val('');
						app.fancybox.open($('footer'), {
							content: response,
							type: 'html',
							afterShow: function() {
								setPopupCustomClass();
								app.components.newsletter.custom.cleanup();
							}
						});
						setTimeout(function() {
							app.fancybox.close();
						}, app.util.getConfig('ajaxNewsletter.thankyouFancyboxCloseTimeout'));
					}
				}
			}).fail(function() {
				target.removeAttr('disabled');
				location.href = location.href;
			}));
	}

	function setDefaultGenderType(currentGenderElement) {
		var $genderLabel = $(this).closest('label');

		if ($genderLabel.length) {
			var inputId = $genderLabel.attr('for');
			var $currentGenderElement = $(currentGenderElement);

			$currentGenderElement
				.find($cache.genderFieldsSel)
				.prop('checked', false)
				.removeAttr('checked');
			$currentGenderElement.find('label').removeClass($cache.classes.checked);
			$currentGenderElement
				.addClass($cache.classes.checked)
				.removeClass($cache.classes.error)
				.find($cache.errorBlockSel)
				.text('');
			$currentGenderElement
				.find('#' + inputId)
				.prop('checked', true)
				.attr('checked', 'checked');
			$genderLabel.addClass($cache.classes.checked);
		}
	}

	function setPopupCustomClass() {
		var $isPopupWrapper = $($cache.additionalContentCheck);
		var $customContent = $isPopupWrapper.find($cache.additionalContentDetect);

		if ($customContent.length !== 0) {
			$isPopupWrapper.addClass($cache.additionalContentDist);
		}
		if (app.device.isMobileView()) {
			$cache.body.addClass($cache.bodyNewsletterClass);
		}
	}

	function getNewsletterCookie() {
		return $.cookie('nlPopupCount');
	}

	app.components = app.components || {};
	app.components.newsletter = app.components.newsletter || {};
	app.components.newsletter.custom = {
		init: function() {
			initializeCache();
			initializeEvents();
		},
		cleanup: cleanUp
	};
})((window.app = window.app || {}), jQuery);
